"use client"
import { ModeToggle } from "@/app/components/DarkMode"

import { signOut } from "next-auth/react"
import Head from "next/head"
import Link from "next/link"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"

import {
  NavigationMenu,
  NavigationMenuInternalLink,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuPermissionsLink,
  navigationMenuTriggerStyle,
} from "@/app/components/ui/navigation-menu"
import { ChevronDown } from "lucide-react"
import { Session } from "next-auth"
import { cn } from "../lib"

const Header = ({
  session,
  admin,
  viewAll,
  dashboards,
  hasReviews,
}: {
  readonly session: Session | null
  readonly admin: boolean
  readonly viewAll: boolean
  readonly dashboards: boolean
  readonly hasReviews?: boolean
}) => (
  <>
    <Head>
      <title>PARS</title>
    </Head>
    <nav>
      <NavigationMenu className="z-20 h-20 max-h-20 w-full max-w-full flex-none justify-between bg-blue-900 text-slate-200 dark:bg-blue-950">
        <NavigationMenuList>
          <NavigationMenuItem>
            <Link href="/" id="homeLink">
              <div className="flex ">
                {/* Intentionally not using next/image because of performance issues. Ignore the linter complaining. */}
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src={"/doeLogo.png"}
                  alt="DOE Logo"
                  className="mx-2 size-[60px]"
                />
                <div className="mb-2 mr-4 scroll-m-20 text-5xl font-extrabold lg:text-6xl">
                  PARS
                </div>
              </div>
            </Link>
          </NavigationMenuItem>
          <NavigationMenuItem id="projectsLink">
            <NavigationMenuInternalLink href="/Projects">
              Projects
            </NavigationMenuInternalLink>
          </NavigationMenuItem>
          <NavigationMenuItem id="contractsLink">
            <NavigationMenuInternalLink href="/Contracts">
              Contracts
            </NavigationMenuInternalLink>
          </NavigationMenuItem>
          <NavigationMenuItem id="reportsLink">
            <NavigationMenuInternalLink href="/Reports">
              Reports
            </NavigationMenuInternalLink>
          </NavigationMenuItem>
          {hasReviews && (
            <NavigationMenuItem>
              <NavigationMenuInternalLink href="/Reviews">
                Reviews
              </NavigationMenuInternalLink>
            </NavigationMenuItem>
          )}
          {viewAll && (
            <NavigationMenuItem>
              <NavigationMenuInternalLink href="/ReportBuilder">
                Report Builder
              </NavigationMenuInternalLink>
            </NavigationMenuItem>
          )}
          <NavigationMenuItem id="empowerLink">
            <NavigationMenuLink
              href="https://empower.pars.doe.gov/empower-saml/"
              className={navigationMenuTriggerStyle()}
            >
              Empower
            </NavigationMenuLink>
          </NavigationMenuItem>

          <NavigationMenuItem>
            <NavigationMenuPermissionsLink
              groups={["IP2M Facilitators", "IP2M User"]}
              session={session}
              href="https://ip2m.pars.doe.gov/"
              className={navigationMenuTriggerStyle()}
            >
              IP2M METRR
            </NavigationMenuPermissionsLink>
          </NavigationMenuItem>
          <NavigationMenuItem id="wikiLink">
            <NavigationMenuLink
              href="https://wiki.pars.doe.gov/"
              className={navigationMenuTriggerStyle()}
            >
              Help
            </NavigationMenuLink>
          </NavigationMenuItem>
        </NavigationMenuList>
        <NavigationMenuList className="self-end">
          <NavigationMenuItem>
            {session?.userinfo?.name ?? ""}
          </NavigationMenuItem>
          {dashboards && <DashboardMenu />}
          {admin && <AdminMenu />}

          <NavigationMenuItem
            className={`${navigationMenuTriggerStyle()} cursor-pointer`}
            onClick={() => signOut()}
          >
            Sign Out
          </NavigationMenuItem>
          <NavigationMenuItem>
            <ModeToggle />
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
    </nav>
  </>
)

export default Header

function DashboardMenu() {
  return (
    <NavigationMenuItem>
      <DropdownMenu>
        <DropdownMenuTrigger
          onClick={(e) => e.stopPropagation()}
          className={cn(navigationMenuTriggerStyle(), "ml-5")}
        >
          Dashboards <ChevronDown />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <Link href="/dashboards/json-uploads">
            <DropdownMenuItem
              onClick={(e) => e.stopPropagation()}
              className={"cursor-pointer"}
            >
              JSON Uploads
            </DropdownMenuItem>
          </Link>
        </DropdownMenuContent>
      </DropdownMenu>
    </NavigationMenuItem>
  )
}

function AdminMenu() {
  return (
    <NavigationMenuItem>
      <DropdownMenu>
        <DropdownMenuTrigger
          onClick={(e) => e.stopPropagation()}
          className={cn(navigationMenuTriggerStyle(), "ml-5")}
        >
          Admin <ChevronDown />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <Link href="/admin/json-dashboard">
            <DropdownMenuItem
              onClick={(e) => e.stopPropagation()}
              className={"cursor-pointer"}
            >
              JSON Dashboard
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/SubProjectAudit">
            <DropdownMenuItem
              onClick={(e) => e.stopPropagation()}
              className={"cursor-pointer"}
            >
              SubProject Audit
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/ManageMetadata">
            <DropdownMenuItem
              onClick={(e) => e.stopPropagation()}
              className={"cursor-pointer"}
            >
              Manage Metadata
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/users-and-contacts">
            <DropdownMenuItem
              onClick={(e) => e.stopPropagation()}
              className={"cursor-pointer"}
            >
              Users and Contacts
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/Documents">
            <DropdownMenuItem
              onClick={(e) => e.stopPropagation()}
              className={"cursor-pointer"}
            >
              Documents
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/reports/acronyms">
            <DropdownMenuItem
              onClick={(e) => e.stopPropagation()}
              className={"cursor-pointer"}
            >
              MSR/QSR Acronyms
            </DropdownMenuItem>
          </Link>
        </DropdownMenuContent>
      </DropdownMenu>
    </NavigationMenuItem>
  )
}
